
.coming-soon {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  flex-direction: column;
}
.coming-soon:first-child {
	margin-top:auto;
}
.coming-soon:last-child {
	margin-bottom: auto;
}
.center.retina-logo img {
  height:90px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copyright {
  margin-top:10px;
}


